import React from "react";
import "./header.index.less";
import fractalLogo from "../../../assets/images/fractal-logo.png";
import mgLogo from "../../../assets/images/mg-logo.png";
import { Link } from "react-router-dom";

const Header: React.FC<{
    rightComponent?: React.ReactElement
}> = ({ rightComponent = <></> }) => {

    return (
        <div className="page-header">
            <div className="logos">
                <div className="mg-logo">
                    <Link to="https://marshallgoldsmith.ai/">
                        <img src={fractalLogo} alt="mg-logo" />
                    </Link>
                </div>
                <div className="mg-logo">
                    <Link to="https://marshallgoldsmith.com/" target="_blank">
                        <img src={mgLogo} alt="mg-logo"/>
                    </Link>
                </div>
            </div>
            <div className="right">
                <div className="extra">
                    {rightComponent}
                </div>
            </div>
        </div>
    )
}

export default Header;
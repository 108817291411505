import React, { useEffect, useRef, useState } from "react";
import {
    IconButton,
    Snackbar,
    TextField,
    Typography,
    Paper,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Modal,
    Card,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../globals/redux/store/store.index";
import * as ChatThunks from "../../chat.thunks";
import * as ChatActions from "../../chat.actions";
import { handleFetchMessages } from "../../chat.thunks";
import { Mic, MicOff, Close, Send, Chat } from "@mui/icons-material";
import "../message/chathistory.index.less";
import AudioMessage from "./audioMessage.index";
import TextMessage from "./textMessage.index";
import genesisLogoUrl from "../../assets/images/genesis-logo.png";
import welcomeVideoUrl from "../../assets/videos/video-1.mp4";
import fillerVideoUrl from "../../assets/videos/video-2.mp4";
import RestartConversationDialog from "../../components/dialogs/restartConversation.index"
import FeedbackDialog from "../dialogs/feedback.index";
import VoiceListening from "../dialogs/voiceListening.index";
import DesktopViewPlaceholder from "../placeholders/desktopView.index";
import MobileViewPlaceholder from "../placeholders/mobileView.index";
import { useWhisper } from "../../hooks/useWhisper";
import mgVideoImageUrl from "../../../../assets/images/mg-video-image.png"
import VideoMessage from "./videoMessage.index";
import { ChatHistoryService } from "../../../../services/chatHistoryService";
import { RenameSessionService } from "../../../../services/renameSessionService";
import { DeleteSessionService } from "../../../../services/deleteSessionService";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import InforDialog from "../dialogs/InforDialog.index";
import { LoadingIcon, MicButton, NoChat } from "../../../../assets/svgicons";
import { Navigate, useNavigate } from "react-router-dom";
import Layout from "../../../layout/layout.index";
import { ConversationSharingService } from "../../../../services/conversationSharingService";
import copy from "copy-to-clipboard";
import IosShareIcon from '@mui/icons-material/IosShare';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const ChatHistory: React.FC<{ onLandingPage?: boolean }> = (onLandingPage) => {
    const dispatch = useDispatch();
    const chatState = useSelector<RootState, { [key: string]: any }>(
        (state) => state.chatV2Reducer,
    );

    const messagesRef = useRef<HTMLDivElement>();
    const inputRef = useRef<HTMLInputElement>(null);
    const videoRef = useRef<HTMLVideoElement>();
    const freeUsage = localStorage.getItem('freeUsageAllowed')
    let showNotNeededComponents = localStorage.getItem('authenticatedUser') ? true : false;

    const [messageInputType, setMessageInputType] = useState<"text" | "voice">(
        "text",
    );
    const [input, setInput] = useState<string>("");

    const [isTyping, setIsTyping] = useState(false);
    const [chatStarted, setChatStarted] = useState<boolean>(false);

    const [feedbackMap, setFeedbackMap] = useState<Record<any, any>>({});
    const [messageIdForFeedback, setMessageIdForFeedback] = useState<
        string | null
    >(null);
    const [showFeedbackDialog, setShowFeedbackDialog] =
        useState<boolean>(false);

    const [audioMessageMap, setAudioMessageMap] = useState<
        Record<string, string[]>
    >({});
    const [currentlyPlayingAudioMessage, setCurrentlyPlayingAudioMessage] =
        useState<string | null>(null);

    const [videoMessageMap, setVideoMessageMap] = useState<
        Record<string, string[]>
    >({});
    const [currentlyPlayingVideoMessage, setCurrentlyPlayingVideoMessage] =
        useState<string | null>(null);

    const [showRecordingDialog, setShowRecordingDialog] =
        useState<boolean>(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] =
        useState<boolean>(false);

    const [videoUrlsToPlay, setVideoUrlsToPlay] = useState<string[]>([]);
    const videoUrlsIndex = useRef<number>(0);
    const navigate = useNavigate();
    const [snackbarProps, setSnackProps] = useState<{
        open: boolean;
        message: string | null;
    }>({
        open: false,
        message: null,
    });

    const {
        secondsRef,
        transcript,
        startRecording,
        stopRecording,
        resetTranscript,
        stopUpdatingSeconds,
    } = useWhisper({
        removeSilence: true,
        timeSlice: 2_000,
        streaming: true,
    });
    const recorderControls = useVoiceVisualizer();
    const {
        audioRef,
        startRecording: startVisualRecording,
        stopRecording: stopVisualRecording,
    } = recorderControls;
    const [time, setTime] = useState({ minutes: 0, seconds: 0 });
    const [isRunning, setIsRunning] = useState(false);
    const cardContent = [
        {
            text: "Can you explain the concept of Stakeholder Centered Coaching?",
            id: 1,
        },
        {
            text: "What is the most important piece of advice you would give to someone looking to improve their leadership skills?",
            id: 2,
        },
        {
            text: `What are some key takeaways from your book 'What Got You Here Won't Get You There'?`,
            id: 3,
        },
    ];
    const [infoModal, setInfoModal] = useState(false);
    const [overloadOpen, setOverloadOpen] = useState(false);

    useEffect(() => {
        let interval;

        if (isRunning) {
            interval = setInterval(() => {
                setTime((prevTime) => {
                    const newSeconds = prevTime.seconds + 1;

                    if (newSeconds === 60) {
                        // Reset seconds to 0 and increment minutes
                        return { minutes: prevTime.minutes + 1, seconds: 0 };
                    }

                    return { ...prevTime, seconds: newSeconds };
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isRunning]);

    const handleVoiceVisualizer = () => {
        // audioRef.current.startRecording()
        startVisualRecording();
    };

    useEffect(() => {
        // console.log(`Elapsed seconds: ${secondsRef.current}`);

        if (secondsRef.current >= 5) {
            // autoSendMessage();
            stopRecording();
            setIsRunning(false);
            setTime({ minutes: 0, seconds: 0 });
            stopVisualRecording();
            stopUpdatingSeconds();
            resetTranscript();
            setShowRecordingDialog(false);
            setMessageInputType("text");
        }
    }, [secondsRef.current]);

    const autoSendMessage = () => {
        // console.log("Auto-sending message...");
        dispatch(
            ChatThunks.postNewMessage(
                input,
                messageInputType,
                // chatState.chatType,
                "voice",
                chatState.chatSessionId,
            ),
        );

        //     if (chatState.chatType === "voice") {
        //         dispatch(ChatThunks.setChatType("text"));
        //     }

        setMessageInputType("text");
        scrollToLastMessage();
        setIsTyping(false);
        setChatStarted(true);
        stopRecording();
        setIsRunning(false);
        setTime({ minutes: 0, seconds: 0 });
        stopVisualRecording();
        setShowRecordingDialog(false);
    };

    useEffect(() => {
        if (isTyping) {
            inputRef.current.focus();
        }
    }, [isTyping]);

    useEffect(() => {
        resetTranscript();
        if (chatState.sendMessageApiStatus === "success") {
            setInput("");
            scrollToLastMessage();
        } else if (chatState.sendMessageApiStatus === "failed") {
            setInput("");
        }

        // eslint-disable-next-line
    }, [chatState.sendMessageApiStatus]);

    useEffect(() => {
        if (chatState.messageFeedbackApiStatus === "success") {
            setShowFeedbackDialog(false);
            setMessageIdForFeedback(null);

            setSnackProps({
                open: true,
                message: "Feedback submitted",
            });
            dispatch(ChatThunks.setMessageFeedbackApiStatus(null));
        } else if (chatState.messageFeedbackApiStatus === "failed") {
            setSnackProps({
                open: true,
                message: "Feedback could not be saved",
            });
            dispatch(ChatThunks.setMessageFeedbackApiStatus(null));
        }
        // eslint-disable-next-line
    }, [chatState.messageFeedbackApiStatus]);

    useEffect(() => {
        setInput(transcript.text);
    }, [transcript]);

    useEffect(() => {
        setAudioMessageMap(chatState.messageIdAudioUrls);
    }, [chatState.messageIdAudioUrls]);

    useEffect(() => {
        setVideoMessageMap(chatState.messageIdVideoUrls);
    }, [chatState.messageIdVideoUrls]);
    // console.log(chatState.messageIdVideoUrls)

    useEffect(() => {
        if (chatState.lastReceivedMessage !== null) {
            if (chatState.lastReceivedMessage.message_type === "voice") {
                setCurrentlyPlayingAudioMessage(
                    chatState.lastReceivedMessage.message_id,
                );
            } else if (chatState.lastReceivedMessage.message_type === "video") {
                setCurrentlyPlayingVideoMessage(
                    chatState.lastReceivedMessage.message_id,
                );
                const urls =
                    chatState.messageIdVideoUrls[
                    chatState.lastReceivedMessage.message_id
                    ];
                // videoRef.current.src = urls[0]
                // videoRef.current.src = urls[videoUrlsIndex.current]
                // videoRef.current.play()
                // videoRef.current.onended = () => {
                //     if (videoUrlsIndex.current <= urls.length) {
                //         videoUrlsIndex.current += 1
                //         videoRef.current.src = urls[videoUrlsIndex.current]
                //     }
                //     else {
                //         videoUrlsIndex.current = 0
                //         dispatch(ChatThunks.clearVideoUrls(chatState.lastReceivedMessage.message_id))
                //     }
                // }
                videoUrlsIndex.current = videoUrlsIndex.current || 0;

                const playNextVideo = () => {
                    if (videoUrlsIndex.current < urls.length) {
                        videoRef.current.src = urls[videoUrlsIndex.current];
                        videoRef.current.play();
                        videoUrlsIndex.current += 1;
                    } else {
                        videoUrlsIndex.current = 0;
                        dispatch(
                            ChatThunks.clearVideoUrls(
                                chatState.lastReceivedMessage.message_id,
                            ),
                        );
                    }
                };

                // Listen for the 'ended' event to play the next video in sequence
                videoRef.current.onended = playNextVideo;

                // Start playing the first video
                playNextVideo();
            }
        }
    }, [chatState.lastReceivedMessage]);

    useEffect(() => {
        setConfirmationDialogOpen(chatState.restartConversation);
    }, [chatState.restartConversation]);

    const handleExampleClick = (exampleText: string) => {
        setInput(exampleText);
        setIsTyping(true);
        inputRef.current.focus();
    };

    const renderMessages = () => {
        let messageComponents = [];
        chatState.messages.forEach((item: Record<any, any>, index: number) => {
            if (item.sender === "user") {
                messageComponents.push(
                    <TextMessage
                        key={index}
                        messageId={item.message_id}
                        text={item.message}
                        sender={item.sender}
                        feedbackAction={feedbackMap[item.message_id]}
                        sendMessageApiStatus={chatState.sendMessageApiStatus}
                        messageFeedbackApiStatus={
                            chatState.messageFeedbackApiStatus
                        }
                        onFeedbackButtonClicked={submitFeedback}
                        onThumbsDownButtonClicked={() => {
                            setMessageIdForFeedback(item.message_id);
                            setShowFeedbackDialog(true);
                        }}
                        onTextContainerResized={scrollToLastMessage}
                        onRetryButtonClicked={() => {
                            dispatch(ChatThunks.retryLastFailedMessage());
                        }}
                        deliveryStatus={
                            item.hasOwnProperty("delivery_status")
                                ? item.delivery_status
                                : null
                        }
                        forHistory={activeChat.length > 0 ? "history" : ""}
                    />,
                );
            } else {
                if (item.message_type === "voice") {
                    messageComponents.push(
                        <AudioMessage
                            key={index}
                            messageId={item.message_id}
                            text={item.message}
                            sender={item.sender}
                            displayAudioControl={
                                index === chatState.messages.length - 1
                            }
                            audioUrls={
                                currentlyPlayingAudioMessage === item.message_id
                                    ? audioMessageMap.hasOwnProperty(
                                        item.message_id,
                                    )
                                        ? audioMessageMap[item.message_id]
                                        : []
                                    : []
                            }
                            feedbackAction={feedbackMap[item.message_id]}
                            messageFeedbackApiStatus={
                                chatState.messageFeedbackApiStatus
                            }
                            onFeedbackButtonClicked={submitFeedback}
                            onThumbsDownButtonClicked={() => {
                                setMessageIdForFeedback(item.message_id);
                                setShowFeedbackDialog(true);
                            }}
                            onTextContainerResized={scrollToLastMessage}
                        />,
                    );
                } else if (item.message_type === "text") {
                    messageComponents.push(
                        <TextMessage
                            key={index}
                            messageId={item.message_id}
                            text={item.message}
                            sender={item.sender}
                            feedbackAction={feedbackMap[item.message_id]}
                            sendMessageApiStatus={
                                chatState.sendMessageApiStatus
                            }
                            messageFeedbackApiStatus={
                                chatState.messageFeedbackApiStatus
                            }
                            onFeedbackButtonClicked={submitFeedback}
                            onThumbsDownButtonClicked={() => {
                                setMessageIdForFeedback(item.message_id);
                                setShowFeedbackDialog(true);
                            }}
                            onTextContainerResized={scrollToLastMessage}
                            onRetryButtonClicked={() => {
                                dispatch(ChatThunks.retryLastFailedMessage());
                            }}
                            deliveryStatus={
                                item.hasOwnProperty("delivery_status")
                                    ? item.delivery_status
                                    : null
                            }
                            forHistory={activeChat.length > 0 ? "history" : ""}
                        />,
                    );
                } else if (item.message_type === "video") {
                    messageComponents.push(
                        <VideoMessage
                            key={index}
                            messageId={item.message_id}
                            text={item.message}
                            sender={item.sender}
                            feedbackAction={feedbackMap[item.message_id]}
                            sendMessageApiStatus={
                                chatState.sendMessageApiStatus
                            }
                            messageFeedbackApiStatus={
                                chatState.messageFeedbackApiStatus
                            }
                            onFeedbackButtonClicked={submitFeedback}
                            onThumbsDownButtonClicked={() => {
                                setMessageIdForFeedback(item.message_id);
                                setShowFeedbackDialog(true);
                            }}
                            onTextContainerResized={scrollToLastMessage}
                            onRetryButtonClicked={() => {
                                dispatch(ChatThunks.retryLastFailedMessage());
                            }}
                            deliveryStatus={
                                item.hasOwnProperty("delivery_status")
                                    ? item.delivery_status
                                    : null
                            }
                        />,
                    );
                }
            }
        });
        return <>{messageComponents}</>;
    };

    const updateFeedbackMap = (messageId: string, action: "TU" | "TD") => {
        let newFeedbackMap = { ...feedbackMap, ...{ [messageId]: action } };
        setFeedbackMap(newFeedbackMap);
    };

    const submitMessage = () => {
        dispatch(ChatThunks.setChatTypeForUpdateMessage(chatState.chatType));
        if (input.trim().length > 0) {
            dispatch(
                ChatThunks.postNewMessage(
                    input.trim(),
                    messageInputType,
                    // chatState.chatType,
                    "voice",
                    chatState.chatSessionId,
                ),
            );

            if (chatState.chatType === "voice") {
                dispatch(ChatThunks.setChatType("text"));
            }

            setMessageInputType("text");
            scrollToLastMessage();
            setIsTyping(false);
            setChatStarted(true);
        }
    };

    const submitFeedback = (
        messageId: string,
        action: "TU" | "TD",
        feedbackText: string | null,
        checkboxResponses: string[],
    ) => {
        dispatch(
            ChatThunks.submitMessageFeedback(
                messageId,
                action,
                feedbackText,
                checkboxResponses,
            ),
        );
        resetTranscript();
        updateFeedbackMap(messageId, action);
    };

    const onSnackbarClose = () => {
        setSnackProps({
            open: false,
            message: null,
        });
    };

    const scrollToLastMessage = () => {
        setTimeout(() => {
            if (messagesRef.current) {
                messagesRef.current.scrollTop =
                    messagesRef.current.scrollHeight + 120;
            }
        }, 200);
    };

    const toggleChatInputType = () => {
        dispatch(
            ChatActions.isMicClicked(1),
        );
        setInput("");
        if (messageInputType === "text") {
            setMessageInputType("voice");
            if (chatState.chatType === "text") {
                dispatch(ChatThunks.setChatType("voice"));
            }
            startRecording();
            stopVisualRecording();
            setIsRunning(true);
            setShowRecordingDialog(true);
        } else if (messageInputType === "voice") {
            stopRecording();
            setIsRunning(false);
            if (chatState.chatType === "voice") {
                dispatch(ChatThunks.setChatType("text"));
            }
            dispatch(ChatThunks.setChatType("text"));
            setMessageInputType("text");
        }
    };

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const handleVideoPlay = () => {
        setIsVideoPlaying(true);
    };
    const handleCookieClick = () => {
        window.open("/cookie", "_blank");
    };
    const handleTermsClick = () => {
        window.open("/t&c", "_blank");
    };
    const handlePrivacyClick = () => {
        window.open("/privacy", "_blank");
    };

    const [userSessions, setUserSessions] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [chatMessages, setChatMessages] = useState([]);
    const [activeChat, setActiveChat] = useState("");
    const [userSessionsLoading, setUserSessionsLoading] = useState("loading");

    const fetchUserSessions = async () => {
        setUserSessionsLoading("loading");
        try {
            const response = await ChatHistoryService.fetchUserSessions();
            setUserSessions(response.data);
            setUserSessionsLoading("loaded");
        } catch (error) {
            console.error("Error fetching user sessions:", error);
        }
    };

    useEffect(() => {
        fetchUserSessions();
    }, [chatState.sendMessageApiStatus]);

    const handleFetchMessagesClick = (chatId: string) => {
        dispatch(ChatActions.setToggleHistory(false))
        console.log(ChatActions.setToggleHistory)
        dispatch(ChatThunks.setIsNewChat(false));
        dispatch(handleFetchMessages(chatId));
        setChatStarted(true);
    };
    const handleNewChat = () => {
        dispatch(ChatThunks.setIsNewChat(true));
        dispatch(ChatThunks.resetState());
        setInput(null);
        dispatch(ChatThunks.resetState());
        setChatStarted(false);
        fetchUserSessions();
        navigate("/chat");
    };

    // const handleFetchMessagesClick = async (chatId) => {
    //     try {
    //         const response = await ChatHistoryService.fetchSessionMessages(chatId);
    //         setChatMessages(response.data);
    //         setSelectedChatId(chatId);
    //         console.log(chatMessages)
    //     } catch (error) {
    //         console.error(`Error fetching messages for chat ${chatId}:`, error);
    //     }
    // };

    useEffect(() => {
        const authToken = localStorage.getItem("authenticationToken");
        const authTokenIsValid =
            authToken !== null && authToken.trim().length > 0;

        const freeUsage = localStorage.getItem('freeUsageAllowed')
        if (!authTokenIsValid && (freeUsage == '0' || !freeUsage)) {
            navigate("/login");
        }
        if (authTokenIsValid) {
            showNotNeededComponents = true
        }
        document.title = "Talk To Marshall Goldsmith.";
    }, []);

    useEffect(() => {
        if (
            chatState.systemOverload &&
            chatState.systemOverload.system_overload
        ) {
            setOverloadOpen(chatState.systemOverload.system_overload);
        }
    }, [chatState.systemOverload]);
    const [isCopied, setIsCopied] = useState(false);

    const handleCloseSnackbar = () => {
        setIsCopied(false);
    };

    const fetchShareId = async (chatId) => {
        try {
            const response = await ConversationSharingService.fetchShareId(chatId);
            return (response.data.share_id);
        } catch (error) {
            console.error('Error fetching shareId:', error);
        }
    };

    const handleShareClick = async (sid) => {
        if (sid) {
            const shareId = await fetchShareId(sid);
            const baseURL = process.env.REACT_APP_BASE_URL
            const shareURL = `${baseURL}/get_shared_chat_messages/${sid}/${shareId}`;
            copy(shareURL)
            setIsCopied(true)
            console.log(shareURL)





        } else {
            console.log('Error fetching Share URL');
        }
    };

    const [open, setOpen] = useState(false);
    const [newTitle, setNewTitle] = useState('');
    const [activeChatTitle, setActiveChatTitle] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedSessionId, setSelectedSessionId] = useState(null);

    const handleRenameClick = (sessionId) => {
        // setSelectedSessionId(sessionId);
        setOpen(true);
    };

    const handleRenameSubmit = async () => {
        if (selectedSessionId && newTitle) {
            try {
                const response = await RenameSessionService.renameSession(selectedSessionId, newTitle);
                // Update the session title in the userSessions array
                const updatedSessions = userSessions.map(session =>
                    session.sid === selectedSessionId ? { ...session, session_title: newTitle } : session
                );
                setUserSessions(updatedSessions);
                setOpen(false);
                setNewTitle('');
            } catch (error) {
                console.error("Failed to rename session:", error);
            }
        }
    };

    const handleDeleteSession = async () => {
        if (selectedSessionId) {
            try {
                const response = await DeleteSessionService.deleteSession(selectedSessionId);
                console.log(response.data);
                const updatedSessions = userSessions.filter(session => session.sid !== selectedSessionId);
                setUserSessions(updatedSessions);
                setOpenDeleteDialog(false);
            } catch (error) {
                console.error("Failed to delete session:", error);
            }
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event, sessionId) => {
        setAnchorEl(event.currentTarget);
        setSelectedSessionId(sessionId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="chat-v2-screen">
            <div className="big-container">
                {chatState.setToggleHistory && showNotNeededComponents && (

                    <div className="chatHistory">

                        <div>
                            <h4 className="chat-history-header">Chat History</h4>
                            <hr
                                style={{ color: "#f6f5f1", marginBottom: "14px" }}
                            />
                        </div>
                        <div className="chat-and-search-button">
                            <Button
                                disabled={chatState.isBotAnswering === "inprogress"}
                                className="new-chat-button"
                                onClick={() => {
                                    handleNewChat();
                                    setActiveChat("");
                                }}
                            >
                                + New Chat
                            </Button>
                        </div>
                        <hr
                            style={{
                                color: "#f6f5f1",
                                margin: "14px 24px 0px 24px ",
                            }}
                        />
                        <div
                            className={`${userSessions.length === 0
                                ? "no-chats-container"
                                : "scrollable-list"
                                }`}
                        >
                            {/* {userSessions && userSessions.length === 0 ? (
                            <div className="no-chats">
                                <NoChat />
                                <div>No chats to show</div>
                            </div>
                        ) :
                         ( */}

                            {
                                // userSessionsLoading === "loading" ? (
                                //     <div className="user-sessions-loading">
                                //         <LoadingIcon color="black" />
                                //     </div>
                                // ) :
                                userSessions.map((session, index) => (
                                    <div
                                        className={`user-session-chat ${activeChat === session.sid
                                            ? "active-user-session"
                                            : ""
                                            }`}
                                        key={session.sid}
                                        onClick={() => {
                                            setActiveChat(session.sid);
                                            setActiveChatTitle(session.session_title)
                                        }}
                                    >
                                        <div
                                            className="session-display"
                                            key={session.sid}
                                            onClick={() => {
                                                handleFetchMessagesClick(session.sid);
                                            }}
                                        >
                                            <div className="new-session-chip">
                                                <p className="new-chat-text">
                                                    {session.session_title
                                                        ? session.session_title
                                                        : session.sid}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="session-functions">
                                            <IconButton title="Click to copy Conversation URL to clipboard" onClick={() => handleShareClick(session.sid)}>
                                                <IosShareIcon className="session-icons" />
                                            </IconButton>
                                            <IconButton onClick={(event) => handleMenuOpen(event, session.sid)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))
                            }
                            {/* )
                        } */}
                        </div>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => { handleMenuClose(); handleRenameClick(activeChat); }}>
                                <ListItemIcon>
                                    <EditOutlinedIcon className="session-icons" />
                                </ListItemIcon>
                                <ListItemText className="menu-item-text">Rename Session</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => { handleMenuClose(); setOpenDeleteDialog(true); }}>
                                <ListItemIcon>
                                    <DeleteOutlineIcon className="session-icons" />
                                </ListItemIcon>
                                <ListItemText className="menu-item-text">Delete Session</ListItemText>
                            </MenuItem>
                        </Menu>
                        {/*Share Dialog*/}
                        <Snackbar
                            open={isCopied}
                            autoHideDuration={3000}
                            onClose={handleCloseSnackbar}
                            message="Conversation URL copied to clipboard!"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            sx={{
                                marginTop: "64px",
                                width: "100%",
                            }}
                        />
                        {/*Rename Dialog*/}
                        <Dialog open={open} onClose={() => setOpen(false)}>
                            <div style={{ width: "280px", borderRadius: "50px" }}>
                                <DialogTitle style={{ fontSize: "20px", padding: "20px", color: "#595959", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    Rename Session
                                </DialogTitle>
                                <DialogContent style={{ fontSize: "17.5px", padding: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        label="New Session Title"
                                        type="text"
                                        fullWidth
                                        value={newTitle}
                                        onChange={(e) => setNewTitle(e.target.value)}
                                        style={{ width: "90%", borderRadius: "10px" }}
                                    />
                                </DialogContent>
                                <DialogActions style={{ display: "flex", justifyContent: "center", padding: "25px" }}>
                                    <Button onClick={() => setOpen(false)} size="small"
                                        variant="outlined" style={{ color: "black", textTransform: "none", borderColor: "#c2c2c2", paddingLeft: "16px", paddingRight: "16px" }}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleRenameSubmit} size="small"
                                        variant="contained" style={{ textTransform: "none", backgroundColor: "#236B48", color: "white", paddingLeft: "16px", paddingRight: "16px" }} autoFocus>
                                        Rename
                                    </Button>
                                </DialogActions>
                            </div>
                        </Dialog>
                        {/* Delete Dialog */}
                        <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                            <div style={{ width: "290px", borderRadius: "50px" }}>
                                <DialogTitle style={{ fontSize: "20px", padding: "14px", color: "#595959", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <DeleteOutlineIcon style={{ fontSize: "45px" }} />
                                </DialogTitle>
                                <DialogContent style={{ fontSize: "16.5px", padding: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    Are you sure you want to delete the session : "{activeChatTitle}" ?
                                </DialogContent>
                                <DialogActions style={{ display: "flex", justifyContent: "center", padding: "25px" }}>
                                    <Button onClick={() => setOpenDeleteDialog(false)} size="small"
                                        variant="outlined" style={{ color: "black", textTransform: "none", borderColor: "#c2c2c2", paddingLeft: "16px", paddingRight: "16px" }}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleDeleteSession} size="small"
                                        variant="contained" style={{ textTransform: "none", backgroundColor: "#236B48", color: "white", paddingLeft: "16px", paddingRight: "16px" }} autoFocus>
                                        Delete
                                    </Button>
                                </DialogActions>
                            </div>
                        </Dialog>
                    </div>)}
                {(!(chatState.setToggleHistory)) && (

                    <div
                        className={
                            chatState.chatType === "video"
                                ? "video-player container"
                                : "container"
                        }
                    >
                        <div
                            className={
                                chatState.chatType === "video"
                                    ? "hidden message-box"
                                    : "message-box"
                            }
                        >
                            {chatStarted === true ? (
                                <>
                                    <div className="messages" ref={messagesRef}>
                                        {chatState.isFetchingMessages ? (
                                            <div className="loading-messages">
                                                <LoadingIcon color="black" />
                                                <p>Loading messages...</p>
                                            </div>
                                        ) : (
                                            renderMessages()
                                        )}
                                    </div>
                                    <>
                                        {chatState.sendMessageApiStatus ===
                                            "inprogress" && (
                                                <div className="waiting-message">
                                                    <div className="lds-ellipsis">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                    <div className="text">
                                                        <Typography variant="body1">
                                                            MarshallBot is thinking...
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )}
                                    </>
                                </>
                            ) : (
                                <div className="message-placeholder">
                                    {/* <DesktopViewPlaceholder
                                    handleExampleClick={handleExampleClick}
                                />
                                <MobileViewPlaceholder
                                    handleExampleClick={handleExampleClick}
                                /> */}
                                    <div className="suggestion-container">
                                        <div className="suggestion-header">
                                            <div>
                                                Ask me a question or pick a
                                                suggestion -
                                            </div>
                                            <div
                                                className="i-icon"
                                                onClick={() => setInfoModal(true)}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                >
                                                    <g
                                                        opacity="0.4"
                                                        clipPath="url(#clip0_1026_655)"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M7.99935 2.00008C4.68564 2.00008 1.99935 4.68637 1.99935 8.00008C1.99935 11.3138 4.68564 14.0001 7.99935 14.0001C11.3131 14.0001 13.9993 11.3138 13.9993 8.00008C13.9993 4.68637 11.3131 2.00008 7.99935 2.00008ZM0.666016 8.00008C0.666016 3.94999 3.94926 0.666748 7.99935 0.666748C12.0494 0.666748 15.3327 3.94999 15.3327 8.00008C15.3327 12.0502 12.0494 15.3334 7.99935 15.3334C3.94926 15.3334 0.666016 12.0502 0.666016 8.00008Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M8.00065 7.33325C8.36884 7.33325 8.66732 7.63173 8.66732 7.99992V10.6666C8.66732 11.0348 8.36884 11.3333 8.00065 11.3333C7.63246 11.3333 7.33398 11.0348 7.33398 10.6666V7.99992C7.33398 7.63173 7.63246 7.33325 8.00065 7.33325Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M7.33398 5.33341C7.33398 4.96522 7.63246 4.66675 8.00065 4.66675H8.00732C8.37551 4.66675 8.67398 4.96522 8.67398 5.33341C8.67398 5.7016 8.37551 6.00008 8.00732 6.00008H8.00065C7.63246 6.00008 7.33398 5.7016 7.33398 5.33341Z"
                                                            fill="black"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1026_655">
                                                            <rect
                                                                width="16"
                                                                height="16"
                                                                fill="white"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="cards-container">
                                            {cardContent.map((each: any) => (
                                                <div
                                                    key={each.id}
                                                    className="card"
                                                    onClick={() =>
                                                        handleExampleClick(
                                                            each.text,
                                                        )
                                                    }
                                                >
                                                    <div className="text">
                                                        "{each.text}"
                                                    </div>
                                                    <div className="arrow-icon">
                                                        <svg
                                                            width="29"
                                                            height="16"
                                                            viewBox="0 0 29 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM28.7071 8.70711C29.0976 8.31658 29.0976 7.68342 28.7071 7.29289L22.3431 0.928932C21.9526 0.538408 21.3195 0.538408 20.9289 0.928932C20.5384 1.31946 20.5384 1.95262 20.9289 2.34315L26.5858 8L20.9289 13.6569C20.5384 14.0474 20.5384 14.6805 20.9289 15.0711C21.3195 15.4616 21.9526 15.4616 22.3431 15.0711L28.7071 8.70711ZM1 9H28V7H1V9Z"
                                                                fill="#236B48"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={
                                chatState.chatType !== "video"
                                    ? "hidden video-player-box"
                                    : "video-player-box"
                            }
                        >
                            <video
                                width="100%"
                                height="100%"
                                ref={videoRef}
                                autoPlay
                                onPlay={handleVideoPlay}
                            >
                                <source type="video/mp4" />
                            </video>
                            {!isVideoPlaying && (
                                <img
                                    src={mgVideoImageUrl}
                                    alt="Image"
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: 1,
                                    }}
                                />
                            )}
                            {chatState.sendMessageApiStatus === "inprogress" && (
                                <div className="waiting-message-video">
                                    <div className="lds-ellipsis-video">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <div className="text-video">
                                        <Typography variant="body1">
                                            MarshallBot is thinking...
                                        </Typography>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`input ${showRecordingDialog ? "input-for-recording" : ""
                                }`}
                        >
                            {showRecordingDialog ? (
                                <div className="recording-container">
                                    <div className="first-container">
                                        <div className="timer">
                                            <div className="second-cirlce">
                                                <div className="first-circle">
                                                    <div className="time">
                                                        {time.minutes +
                                                            " : " +
                                                            time.seconds}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div
                                        className="audio-wave"
                                        ref={waveformRef}
                                    ></div> */}
                                        {/* <AudioWaveform audioBlob={transcript.blob} /> */}
                                        <div className="mg-voice-visualizer">
                                            <VoiceVisualizer
                                                ref={audioRef}
                                                controls={recorderControls}
                                                height={70}
                                                width={"100%"}
                                                mainBarColor="#FBB117"
                                                canvasContainerClassName="canvas-container"
                                                isControlPanelShown={false}
                                                barWidth={4}
                                                secondaryBarColor="#EFEEEE"
                                            />
                                        </div>
                                        <div
                                            className="stop-recording"
                                            onClick={() => {
                                                stopRecording();
                                                setIsRunning(false);
                                                setTime({
                                                    minutes: 0,
                                                    seconds: 0,
                                                });
                                                stopVisualRecording();
                                                stopUpdatingSeconds();
                                                resetTranscript();
                                                setShowRecordingDialog(false);
                                                setMessageInputType("text");
                                            }}
                                        >
                                            <p>Stop recording</p>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="34"
                                                height="35"
                                                viewBox="0 0 34 35"
                                                fill="none"
                                            >
                                                <path
                                                    d="M16.6667 0.833252C14.478 0.833252 12.3107 1.26435 10.2886 2.10193C8.26652 2.9395 6.4292 4.16716 4.88155 5.71481C1.75595 8.84041 0 13.0796 0 17.4999C0 21.9202 1.75595 26.1594 4.88155 29.285C6.4292 30.8327 8.26652 32.0603 10.2886 32.8979C12.3107 33.7355 14.478 34.1666 16.6667 34.1666C21.0869 34.1666 25.3262 32.4106 28.4518 29.285C31.5774 26.1594 33.3333 21.9202 33.3333 17.4999C33.3333 15.3112 32.9022 13.144 32.0647 11.1219C31.2271 9.09977 29.9994 7.26245 28.4518 5.71481C26.9041 4.16716 25.0668 2.9395 23.0447 2.10193C21.0226 1.26435 18.8554 0.833252 16.6667 0.833252ZM11.6667 12.4999H21.6667V22.4999H11.6667"
                                                    fill="#F06055"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="second-container">
                                        <div className="loader-and-input">
                                            <div className="bouncing-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <p>{input}</p>
                                        </div>
                                        <div className="silence-warning">
                                            {secondsRef.current > 1
                                                ? `Silence detected. Stopping recording in ${secondsRef.current}s`
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="text-field">
                                    <TextField
                                        fullWidth
                                        multiline
                                        disabled={
                                            chatState.sendMessageApiStatus ===
                                            "inprogress" ||
                                            chatState.isBotAnswering ===
                                            "inprogress"
                                        }
                                        className="input-field"
                                        maxRows={2}
                                        placeholder={
                                            messageInputType === "voice"
                                                ? "Listening"
                                                : "Type your message here..."
                                        }
                                        value={input !== null ? input : ""}
                                        inputRef={inputRef}
                                        onKeyUp={(event) => {
                                            if (event.key === "Enter") {
                                                if (input.trim().length > 0) {
                                                    chatState.isBotAnswering ===
                                                        "success" &&
                                                        submitMessage();
                                                    // setActiveChat("");
                                                } else {
                                                    setInput("");
                                                }
                                            }
                                        }}
                                        onChange={(event) => {
                                            setInput(event.target.value);
                                            setIsTyping(
                                                event.target.value.length > 0,
                                            );
                                        }}
                                        InputProps={{
                                            readOnly:
                                                process.env.NODE_ENV ===
                                                "production" &&
                                                messageInputType === "voice",
                                            endAdornment: (
                                                <>
                                                    <IconButton
                                                        title="Send"
                                                        disabled={
                                                            chatState.isBotAnswering ===
                                                            "inprogress" ||
                                                            chatState.sendMessageApiStatus ===
                                                            "inprogress"
                                                        }
                                                        onClick={submitMessage}
                                                        style={{
                                                            color: isTyping
                                                                ? "white"
                                                                : "#525252",
                                                            backgroundColor:
                                                                isTyping
                                                                    ? "mediumseagreen"
                                                                    : "transparent",
                                                            borderRadius: isTyping
                                                                ? "30%"
                                                                : 0,
                                                        }}
                                                    >
                                                        <Send />
                                                    </IconButton>
                                                </>
                                            ),
                                        }}
                                    />
                                    <div>
                                        <IconButton
                                            className="icon-button microphone"
                                            title={
                                                messageInputType === "text"
                                                    ? "Turn On Microphone"
                                                    : "Turn Off Microphone"
                                            }
                                            disabled={
                                                chatState.sendMessageApiStatus ===
                                                "inprogress" ||
                                                chatState.isBotAnswering ===
                                                "inprogress"
                                            }
                                            onClick={(event) => {
                                                toggleChatInputType();
                                                handleVoiceVisualizer();
                                            }}
                                        >
                                            <div className="mic-button">
                                                <MicButton />
                                            </div>
                                        </IconButton>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <div className="powered-by">
                        <div className="text">Powered by</div>
                        <img src={genesisLogoUrl} alt="Genesis Logo" />
                    </div> */}
                    </div>
                )}</div>
            {showNotNeededComponents && (<div className="footer">
                <div style={{ display: "flex", alignItems: "center" }}>
                    {window.innerWidth <= 480 ?
                        <p style={{ color: "#595959", textTransform: "none", paddingLeft: "12px", fontSize: "10px" }}>
                            © 2024 Fractal Analytics Inc.
                        </p> : (
                            <p className="faText">
                                All rights reserved © 2024 Fractal Analytics Inc.
                            </p>
                        )
                    }
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                        variant="text"
                        className="terms"
                        onClick={handleCookieClick}
                    >
                        Cookies
                    </Button>

                    <Button
                        variant="text"
                        className="terms"
                        onClick={handlePrivacyClick}
                    >
                        {window.innerWidth <= 480 ?
                            <p>
                                Privacy
                            </p> : (
                                <p>
                                    Privacy Policy
                                </p>
                            )
                        }
                    </Button>
                    <Button
                        variant="text"
                        className="terms"
                        onClick={handleTermsClick}
                    >
                        {window.innerWidth <= 480 ?
                            <p>
                                Terms
                            </p> : (
                                <p>
                                    Terms of Use
                                </p>
                            )
                        }
                    </Button>
                </div>
            </div>)}

            <Paper className="no-screen-support">
                <Typography variant="body1" align="center">
                    We do not support landscape mode on mobile devices. Switch
                    to portrait mode for better experience.
                </Typography>
            </Paper>

            {/* Dialogs */}
            <RestartConversationDialog
                isOpen={confirmationDialogOpen}
                onClose={() => {
                    setConfirmationDialogOpen(false);
                }}
                onConfirm={() => {
                    setConfirmationDialogOpen(false);
                    setInput(null);
                    dispatch(ChatThunks.resetState());
                    setChatStarted(false);
                }}
            />

            <FeedbackDialog
                isOpen={showFeedbackDialog}
                onClose={() => {
                    setShowFeedbackDialog(false);
                    setMessageIdForFeedback(null);
                }}
                onConfirm={(
                    feedbackText: string,
                    feedbackChoices: string[],
                ) => {
                    submitFeedback(
                        messageIdForFeedback,
                        "TD",
                        feedbackText,
                        feedbackChoices,
                    );
                }}
                messageFeedbackApiStatus={chatState.messageFeedbackApiStatus}
            />

            <VoiceListening
                isOpen={false}
                onClose={() => {
                    stopRecording();
                    resetTranscript();
                    setShowRecordingDialog(false);
                    setMessageInputType("text");
                    setInput("");
                }}
                onConfirm={() => {
                    if (input.trim().length > 0) {
                        stopRecording();
                        submitMessage();
                        setActiveChat("");
                        setShowRecordingDialog(false);
                    }
                }}
                isDisabled={!input || (input && input.trim().length === 0)}
            />

            <Snackbar
                sx={{ maxWidth: 240 }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarProps.open}
                autoHideDuration={2000}
                message={snackbarProps.message}
                onClose={onSnackbarClose}
                action={
                    <IconButton onClick={onSnackbarClose}>
                        <Close htmlColor="white" />
                    </IconButton>
                }
            />
            <InforDialog
                isOpen={infoModal}
                onClose={() => setInfoModal(false)}
            />
            {/* <Modal open={false} className="system-overload-modal">
                <Card className="system-overload-card">
                    <div className="system-overload-content">
                        <p>Exceed limit message</p>
                        <Button variant="contained">OK</Button>
                    </div>
                </Card>
            </Modal> */}
            <Snackbar
                sx={{ maxWidth: 240 }}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={overloadOpen}
                autoHideDuration={2000}
                message={chatState.systemOverload.message}
                onClose={() => setOverloadOpen(false)}
                action={
                    <IconButton onClick={() => setOverloadOpen(false)}>
                        <Close htmlColor="white" />
                    </IconButton>
                }
            />
        </div>
    );
};

export default ChatHistory;


import * as Actions from "./layout.actions";
import { GlobalStateReducerModel } from "./layout.models";

const initialState: GlobalStateReducerModel = {
    showNotification: false,
    notificationMessage: null,
    notificationSeverity: null,
    headerRightComponent: null
};

const LayoutReducer = (
    state = initialState,
    action: { type: any; payload: any },
) => {
    switch (action.type) {
        case Actions.RESET_STATE:
            return {
                ...initialState,
            };
        case Actions.SET_NOTIFICATION:
            return {
                ...state,
                ...action.payload,
            };
        case Actions.CLOSE_NOTIFICATION:
            return {
                ...state,
                ...action.payload,
            };
        case Actions.SET_HEADER_RIGHT_COMPONENT:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default LayoutReducer;

import React, { useState, useEffect } from "react";
import "./register.index.less";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
    Lock,
    Person,
    Info,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import {
    FormControl,
    OutlinedInput,
    InputAdornment,
    Collapse,
    Alert,
    Paper,
    Popover,
    IconButton,
    Typography,
    Button,
} from "@mui/material";
import mgHeroImageUrl from "../../assets/images/mg-background.png";
import mgFaceCropHeroImageUrl from "../../assets/images/mg-face-crop.jpeg";
import mgLogoImageUrl from "../../assets/images/mg-logo.png";
import fractalLogo from "../../assets/images/fractal-logo.png";
import { RootState } from "../../globals/redux/store/store.index";
import * as RegisterThunks from "./register.thunks";
import marshal_bg from "../../assets/images/marshall_bg.png";

const Register = () => {
    // Selectors
    const registerState = useSelector<RootState, { [key: string]: any }>(
        (state) => state.registerReducer,
    );

    const [username, setUsername] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [firstname, setFirstname] = useState<string | null>(null);
    const [lastname, setLastname] = useState<string | null>(null);
    const [password, setUserPassword] = useState<string | null>(null);
    const [apiInProgress, setApiInProgress] = useState<boolean>(false);
    const [registrationStatus, setRegistrationStatus] = useState<string | null>(
        null,
    );
    const [registrationResponse, setRegistrationResponse] = useState<
        string | null
    >(null);
    const [status, setStatus] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     const authToken = localStorage.getItem("authenticationToken");
    //     const authTokenIsValid = (authToken !== null) || (authToken && authToken.trim().length > 0);

    //     if (authTokenIsValid === true) {
    //         navigate("/chat");
    //     }
    //     else {
    //         localStorage.removeItem("question");
    //         localStorage.removeItem("answer");
    //     }
    //     // eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //     setRegistrationStatus(registerState.registrationStatus);
    //     if (registerState.registrationStatus === "in progress") {
    //         setRegistrationResponse("Registration in progress!");
    //         setApiInProgress(true);
    //     }
    //     else if (registerState.registrationStatus === "successful") {
    //         setRegistrationResponse("Registration successful!");
    //         setTimeout(() => {
    //             setApiInProgress(false);
    //             navigate("/chat");
    //         }, 500);
    //     }
    //     else if (registerState.registrationStatus === "failed") {
    //         setApiInProgress(false);
    //         setRegistrationResponse("Registration failed!");
    //     }
    //     // eslint-disable-next-line
    // }, [registerState]);

    useEffect(() => {
        setRegistrationStatus(registerState.registrationStatus);

        if (registerState.registrationStatus === "in progress") {
            setRegistrationResponse("Registration in progress!");
            setApiInProgress(true);
        } else if (registerState.registrationStatus === "successful") {
            setRegistrationResponse("Registration successful!");
            setStatus("OTP sent to your email id!");
            setTimeout(() => {
                setApiInProgress(false);
                // navigate(`/verify-otp?email=${email}`);
                navigate(`/verify-otp?email=${email}&flow=register`);
            }, 500);
        } else if (registerState.registrationStatus === "failed") {
            setApiInProgress(false);
            setRegistrationResponse("Registration failed!");
        }
    }, [registerState]);

    useEffect(() => {
        document.title = "Register";
    }, []);

    const [errorMessages, setErrorMessages] = useState({});
    const [errorsDisplayed, setErrorsDisplayed] = useState(false);

    const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setApiInProgress(true);

        try {
            await dispatch(
                RegisterThunks.registerUser(
                    username,
                    email,
                    firstname,
                    lastname,
                    password,
                ),
            );
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.errors
            ) {
                const errorObject = error.response.data.errors;
                setErrorMessages(errorObject);
                setErrorsDisplayed(true);
            } else {
                setErrorMessages({});
            }
            setApiInProgress(false);
        }
    };

    const passwordErrors = errorMessages && errorMessages["password"];
    // const usernameErrors = errorMessages && errorMessages["username"];
    const emailErrors = errorMessages && errorMessages["email"];
    const firstnameErrors = errorMessages && errorMessages["first_name"];
    const lastnameErrors = errorMessages && errorMessages["last_name"];

    // const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     setApiInProgress(true);
    //     dispatch(RegisterThunks.registerUser(username, email, firstname, lastname, password));
    // }

    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    // const handlePopoverOpen = () => {
    //     setPopoverOpen(true);
    // };

    // const handlePopoverClose = () => {
    //     setPopoverOpen(false);
    // };

    const open = Boolean(anchorEl);
    const handleCookieClick = () => {
        window.open("/cookie", "_blank");
    };
    const handleTermsClick = () => {
        window.open("/t&c", "_blank");
    };
    const handlePrivacyClick = () => {
        window.open("/privacy", "_blank");
    };

    return (
        <>
            <div className="register">
                <div className="mg-left-container">
                    <img src={marshal_bg} className="mg-left-image" />
                </div>
                <div className="login-right-container">
                    <div className="inside-container">
                        <div className="card-first-part">
                            <img
                                src={fractalLogo}
                                alt="Logo"
                                className="logo-image"
                            />
                            <div className="vertical-line"></div>
                            <img
                                src={mgLogoImageUrl}
                                alt="MG-Logo"
                                className="logo-image"
                            />
                        </div>
                        <div className="card-second-part">
                            <div className="sign-in-line">
                                <div>
                                    <hr className="horizontal-line" />
                                </div>
                                <div className="sign-in-text">
                                    Create an account
                                </div>
                                <div>
                                    <hr className="horizontal-line" />
                                </div>
                            </div>
                            <div className="form-container">
                                <form onSubmit={onFormSubmit} className="form">
                                    <div className="form-fields-container">
                                        <FormControl className="form-field">
                                            <label
                                                htmlFor="first-name"
                                                className="label-text"
                                            >
                                                First Name
                                            </label>
                                            <OutlinedInput
                                                sx={{
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        borderColor: "#236B48",
                                                    },
                                                }}
                                                id="first-name"
                                                type="text"
                                                autoFocus
                                                placeholder="First Name"
                                                className="text-input"
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    setFirstname(
                                                        (first_name) =>
                                                            event.target.value,
                                                    );
                                                    if (firstnameErrors) {
                                                        setErrorMessages(
                                                            (prevErrors) => ({
                                                                ...prevErrors,
                                                                first_name: null,
                                                            }),
                                                        );
                                                    }
                                                }}
                                            />
                                            {firstnameErrors && (
                                                <div style={{ color: "red" }}>
                                                    <ul
                                                        style={{
                                                            margin: "2px",
                                                            paddingInlineStart:
                                                                "5px",
                                                            listStyleType: "none",
                                                        }}
                                                    >
                                                        {firstnameErrors.map(
                                                            (errorMsg, index) => (
                                                                <li
                                                                    key={index}
                                                                    style={{
                                                                        marginBottom:
                                                                            "5px",
                                                                    }}
                                                                >
                                                                    {errorMsg}
                                                                </li>
                                                            ),
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        </FormControl>
                                        <FormControl className="form-field">
                                            <label
                                                htmlFor="last-name"
                                                className="label-text"
                                            >
                                                Last Name
                                            </label>
                                            <OutlinedInput
                                                sx={{
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        borderColor: "#236B48",
                                                    },
                                                }}
                                                type="text"
                                                placeholder="Last Name"
                                                className="text-input"
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    setLastname(
                                                        (last_name) =>
                                                            event.target.value,
                                                    );
                                                    if (lastnameErrors) {
                                                        setErrorMessages(
                                                            (prevErrors) => ({
                                                                ...prevErrors,
                                                                last_name: null,
                                                            }),
                                                        );
                                                    }
                                                }}
                                            />
                                            {lastnameErrors && (
                                                <div style={{ color: "red" }}>
                                                    <ul
                                                        style={{
                                                            margin: "2px",
                                                            paddingInlineStart:
                                                                "5px",
                                                            listStyleType: "none",
                                                        }}
                                                    >
                                                        {lastnameErrors.map(
                                                            (errorMsg, index) => (
                                                                <li
                                                                    key={index}
                                                                    style={{
                                                                        marginBottom:
                                                                            "5px",
                                                                    }}
                                                                >
                                                                    {errorMsg}
                                                                </li>
                                                            ),
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        </FormControl>
                                    </div>

                                    <FormControl className="form-field">
                                        <label
                                            htmlFor="email"
                                            className="label-text"
                                        >
                                            Email Address
                                        </label>
                                        <OutlinedInput
                                            sx={{
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: "#236B48",
                                                },
                                            }}
                                            type="text"
                                            placeholder="Email"
                                            className="text-input"
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                setEmail(
                                                    (email) => event.target.value,
                                                );
                                                if (emailErrors) {
                                                    setErrorMessages(
                                                        (prevErrors) => ({
                                                            ...prevErrors,
                                                            email: null,
                                                        }),
                                                    );
                                                }
                                            }}
                                        />
                                        {emailErrors && (
                                            <div style={{ color: "red" }}>
                                                <ul
                                                    style={{
                                                        margin: "2px",
                                                        paddingInlineStart: "5px",
                                                        listStyleType: "none",
                                                    }}
                                                >
                                                    {emailErrors.map(
                                                        (errorMsg, index) => (
                                                            <li
                                                                key={index}
                                                                style={{
                                                                    marginBottom:
                                                                        "5px",
                                                                }}
                                                            >
                                                                {errorMsg}
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </FormControl>

                                    <FormControl className="form-field">
                                        <label
                                            htmlFor="password"
                                            className="label-text"
                                        >
                                            Password
                                        </label>
                                        <OutlinedInput
                                            sx={{
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: "#236B48",
                                                },
                                            }}
                                            type={
                                                showPassword ? "text" : "password"
                                            }
                                            placeholder="Password"
                                            className="text-input"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword,
                                                            )
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="password-info"
                                                        onClick={handlePopoverOpen}
                                                    // onMouseEnter={handlePopoverOpen}
                                                    // onMouseLeave={handlePopoverClose}
                                                    >
                                                        <Info />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                setUserPassword(
                                                    (userPassword) =>
                                                        event.target.value,
                                                );
                                                if (passwordErrors) {
                                                    setErrorMessages(
                                                        (prevErrors) => ({
                                                            ...prevErrors,
                                                            password: null,
                                                        }),
                                                    );
                                                }
                                            }}
                                        />
                                        {passwordErrors && (
                                            <div style={{ color: "red" }}>
                                                <ul
                                                    style={{
                                                        margin: 0,
                                                        paddingInlineStart: "0px",
                                                        listStyleType: "none",
                                                    }}
                                                >
                                                    {passwordErrors.map(
                                                        (errorMsg, index) => (
                                                            <li
                                                                key={index}
                                                                style={{
                                                                    marginBottom:
                                                                        "5px",
                                                                }}
                                                            >
                                                                {errorMsg}
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </FormControl>
                                    <Popover
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                        }}
                                        disableRestoreFocus
                                    >
                                        <Typography sx={{ p: 2 }}>
                                            Password requirements:
                                            <ul>
                                                <li>
                                                    Length should be between 8 to 20
                                                    characters
                                                </li>
                                                <li>
                                                    Should contain at least one
                                                    alphabet (e.g. A to Z) and one
                                                    digit (e.g. 0 to 9)
                                                </li>
                                                <li>
                                                    No special characters allowed
                                                    (for e.g. @,%,$)
                                                </li>
                                                <li>
                                                    Password is case sensitive (e.g.
                                                    upper case -A to Z vs lower case
                                                    -a to z)
                                                </li>
                                            </ul>
                                        </Typography>
                                    </Popover>

                                    <FormControl className="form-field button-field">
                                        <LoadingButton
                                            sx={{
                                                marginTop: "10px",
                                                backgroundColor: "#236B48",
                                                "&:hover": {
                                                    backgroundColor: "#236B48",
                                                },
                                            }}
                                            loading={apiInProgress}
                                            variant="contained"
                                            type="submit"
                                            size="large"
                                            className="button"
                                            style={{ textTransform: "none" }}
                                        >
                                            <span>Create Account</span>
                                        </LoadingButton>
                                    </FormControl>
                                </form>
                                <div
                                    className="have-an-account"
                                    onClick={() => navigate("/login/")}
                                >
                                    Have an account? Log in{" "}
                                </div>
                                <Collapse
                                    orientation="vertical"
                                    in={status !== null}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Alert
                                            sx={{ width: "360px" }}
                                            severity="success"
                                        >
                                            {status}
                                        </Alert>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p className="faText-login">
                        All rights reserved © 2024 Fractal Analytics Inc.
                    </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                        variant="text"
                        className="terms-login"
                        style={{
                            color: "#595959",
                            textTransform: "none",
                            paddingRight: "30px",
                            fontSize: "smaller",
                        }}
                        onClick={handleCookieClick}
                    >
                       Cookies
                    </Button>
                    <Button
                        variant="text"
                        className="terms-login"
                        style={{
                            color: "#595959",
                            textTransform: "none",
                            paddingRight: "30px",
                            fontSize: "smaller",
                        }}
                        onClick={handlePrivacyClick}
                    >
                        Privacy Policy
                    </Button>
                    <Button
                        variant="text"
                        className="terms-login"
                        style={{
                            color: "#595959",
                            textTransform: "none",
                            paddingRight: "30px",
                            fontSize: "smaller",
                        }}
                        onClick={handleTermsClick}
                    >
                        Terms of Use
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Register;

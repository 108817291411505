import { Dispatch } from "redux";
import * as ChatActions from "./chat.actions";
import * as GlobalThunks from "../layout/layout.thunks";
import { ChatService } from "../../services/chatService";
import { RootState } from "../../globals/redux/store/store.index";
import { ChatHistoryService } from "../../services/chatHistoryService";
import { chunkifyText } from "./chat.utils";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useSelector } from "react-redux";
import { useState } from "react";
import ReconnectingEventSource from "reconnecting-eventsource";
export const resetState = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(ChatActions.resetState());
    };
};
export const postNewMessage = (
    message: string,
    messageType: string,
    responseMessageType: string,
    chatSessionId: string | null,
    appendMessage: boolean = true,
) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            const { isNewChat } = getState().chatV2Reducer;
            let postData = {
                message: message,
                message_type: messageType, // text | voice
                response_message_type: responseMessageType, // text | voice | video
                username: localStorage.getItem("authenticatedUser"),
            };

            if (appendMessage === true) {
                dispatch(
                    ChatActions.appendMessage({
                        sender: "user",
                        message: message,
                        message_type: messageType,
                    }),
                );
            }
            dispatch(ChatActions.setSendMessageApiStatus("inprogress"));
            dispatch(ChatActions.isBotAnswering("inprogress"));
            // responseMessageType = 'voice' // for testing remove

            if (chatSessionId === null) {
                const chatSessionIdRequest = ChatService.getNewChatSessionId();
                chatSessionIdRequest
                    .then((response) => {
                        dispatch(
                            ChatActions.setChatSessionId(response.data.sid),
                        );

                        if (responseMessageType === "text") {
                            const postMessageRequest =
                                ChatService.postTextMessage(
                                    response.data.sid,
                                    postData,
                                );
                            postMessageRequest.then((response) => {
                                if (response.data.system_overload) {
                                    dispatch(
                                        ChatActions.setSystemOverload(
                                            response.data.system_overload,
                                            response.data.message,
                                        ),
                                    );
                                }
                                dispatch(
                                    ChatActions.setLastMessageDeliveryStatus(
                                        "delivered",
                                    ),
                                );
                            });
                        } else if (responseMessageType === "voice") {
                            dispatch(ChatActions.setAudioMessageId(response.data.sid));
                            const postMessageRequest = ChatService.postMessage(
                                response.data.sid,
                                postData,
                            );
                            postMessageRequest.then((response) => {
                                if (response.data.system_overload) {
                                    dispatch(
                                        ChatActions.setSystemOverload(
                                            response.data.system_overload,
                                            response.data.message,
                                        ),
                                    );
                                }
                                dispatch(
                                    ChatActions.setLastMessageDeliveryStatus(
                                        "delivered",
                                    ),
                                );
                            });
                        }
                        const sid = response.data.sid;
                        const ctrl = new AbortController();

                        fetchEventDataTextMessage(
                            sid,
                            ctrl,
                            dispatch,
                            getState,
                            responseMessageType,
                        );
                    })
                    .catch((reason: any) => {
                        dispatch(
                            ChatActions.setLastMessageDeliveryStatus("failed"),
                        );
                        dispatch(ChatActions.setSendMessageApiStatus("failed"));
                    });
            } else {
                if (responseMessageType === "text") {
                    const ctrl = new AbortController();
                    const postMessageRequest = ChatService.postTextMessage(
                        chatSessionId,
                        postData,
                    );
                    postMessageRequest.then((response) => {
                        if (response.data.system_overload) {
                            dispatch(
                                ChatActions.setSystemOverload(
                                    response.data.system_overload,
                                    response.data.message,
                                ),
                            );
                        }
                        dispatch(
                            ChatActions.setLastMessageDeliveryStatus(
                                "delivered",
                            ),
                        );
                    });
                    fetchEventDataTextMessage(
                        chatSessionId,
                        ctrl,
                        dispatch,
                        getState,
                        responseMessageType,
                    );
                } else if (responseMessageType === "voice") {
                    const ctrl = new AbortController();
                    const postMessageRequest = ChatService.postMessage(
                        chatSessionId,
                        postData,
                    );

                    postMessageRequest.then((response) => {
                        if (response.data.system_overload) {
                            dispatch(
                                ChatActions.setSystemOverload(
                                    response.data.system_overload,
                                    response.data.message,
                                ),
                            );
                        }
                        dispatch(
                            ChatActions.setLastMessageDeliveryStatus(
                                "delivered",
                            ),
                        );
                    });
                    fetchEventDataTextMessage(
                        chatSessionId,
                        ctrl,
                        dispatch,
                        getState,
                        responseMessageType,
                    );
                    // dispatch(ChatActions.setAudioMessageId(chatSessionId));
                }
            }
            dispatch(ChatActions.isBotAnswering("success"));
        } catch (error) {
            dispatch(ChatActions.setLastMessageDeliveryStatus("failed"));
            dispatch(ChatActions.setSendMessageApiStatus("failed"));
            dispatch(
                GlobalThunks.setNotificationMessage(
                    true,
                    "Message not delivered",
                    "error",
                ),
            );
        }
    };
};

export const submitMessageFeedback = (
    messageId: string,
    action: "TU" | "TD",
    text: string | null,
    checkboxResponses: string[],
) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            dispatch(ChatActions.setMessageFeedbackApiStatus("inprogress"));
            await ChatService.sendFeedback(
                messageId,
                action,
                text,
                checkboxResponses,
            );
            dispatch(ChatActions.setMessageFeedbackApiStatus("success"));
        } catch (error) {
            dispatch(ChatActions.setMessageFeedbackApiStatus("failed"));
            dispatch(
                GlobalThunks.setNotificationMessage(
                    true,
                    "Failed to save message feedback",
                    "error",
                ),
            );
        }
    };
};

export const setMessageFeedbackApiStatus = (
    status: "inprogress" | "success" | "failed" | null,
) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(ChatActions.setMessageFeedbackApiStatus(status));
    };
};

export const fetchEventDataTextMessage = async (
    sessionId: any,
    ctrl: AbortController,
    dispatch: Dispatch<any>,
    getState: () => RootState,
    responseMessageType?: string,
) => {
    window.addEventListener("beforeunload", function (e) {
        ctrl.abort();
    });

    const eventSource = new ReconnectingEventSource(
        `${process.env.REACT_APP_API_BASE_URL}/events/${sessionId}`,
    );

    eventSource.onopen = (response: any) => {
        console.log("event source opened");
        dispatch(ChatActions.setStreamStarted(1));
        if (
            response.status >= 400 &&
            response.status < 500 &&
            response.status !== 429
        ) {
            console.log("error");
            eventSource.close();
        }
    };
    eventSource.onmessage = (event) => {
        if (event.data) {
            dispatch(ChatActions.isBotAnswering("inprogress"));
            const edata = JSON.parse(event.data);
            if (responseMessageType === "voice") {
                console.log("Calling Audio");
                dispatch(ChatActions.setAudioMessageId(sessionId));
            }

            if (
                edata["text"] &&
                edata["type"] &&
                edata["type"] == "text" &&
                edata.sequence != "END"
            ) {
                const currentState = getState().chatV2Reducer;
                if (sessionId == currentState.chatSessionId) {
                    dispatch(ChatActions.updateMessage(edata["text"]));
                    dispatch(ChatActions.setSendMessageApiStatus("success"));
                }
            }
            if (edata.sequence === "END") {
                console.log("event source closed");
                eventSource.close();
                dispatch(ChatActions.isBotAnswering("success"));
            }
        } else {
            console.log("event.data is empty");
        }
    };

    eventSource.onerror = (err) => {
        eventSource.close();
        dispatch(ChatActions.setSendMessageApiStatus("failed"));
        if (err) {
            throw err;
        }
    };
    // fetchEventSource(
    //     `${process.env.REACT_APP_API_BASE_URL}/events/${sessionId}`,
    //     {
    //         openWhenHidden: true,
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Accept: "text/event-stream",
    //             Authorization: `Bearer ${localStorage.getItem(
    //                 "authenticationToken",
    //             )}`,
    //         },
    //         signal: ctrl.signal,
    //         async onopen(response) {
    //             if (
    //                 response.status >= 400 &&
    //                 response.status < 500 &&
    //                 response.status !== 429
    //             ) {
    //                 console.log("error");
    //                 ctrl.abort();
    //             }
    //         },
    //         onmessage: (event) => {
    //             if (event.data) {
    //                 dispatch(ChatActions.isBotAnswering("inprogress"));
    //                 const edata = JSON.parse(event.data);
    //                 if (responseMessageType === "voice") {
    //                     //calling audio
    //                     dispatch(ChatActions.setAudioMessageId(sessionId));
    //                 }
    //                 // if (edata.sequence == "START" && edata["message_id"]) {
    //                 //     if (responseMessageType === "voice") {
    //                 //         console.log("Calling Audio", sessionId);
    //                 //         dispatch(ChatActions.setAudioMessageId(sessionId));
    //                 //     }
    //                 // }
    //                 if (
    //                     edata["text"] &&
    //                     edata["type"] &&
    //                     edata["type"] == "text" &&
    //                     edata.sequence != "END"
    //                 ) {
    //                     const currentState = getState().chatV2Reducer;
    //                     if (sessionId == currentState.chatSessionId) {
    //                         dispatch(ChatActions.updateMessage(edata["text"]));
    //                         dispatch(
    //                             ChatActions.setSendMessageApiStatus("success"),
    //                         );
    //                     }
    //                 }
    //                 if (edata.sequence === "END") {
    //                     ctrl.abort();
    //                     dispatch(ChatActions.isBotAnswering("success"));
    //                 }
    //             } else {
    //                 console.log("event.data is empty");
    //             }
    //         },
    //         onerror: (err) => {
    //             ctrl.abort();
    //             dispatch(ChatActions.setSendMessageApiStatus("failed"));
    //             if (err) {
    //                 throw err;
    //             }
    //         },
    //         onclose: () => {
    //             ctrl.abort();
    //             dispatch(ChatActions.setSendMessageApiStatus("failed"));
    //             // console.log("closed");
    //         },
    //     },
    // );
};

export const setChatType = (type: "text" | "voice" | "video") => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(ChatActions.setChatType(type));
    };
};

export const setIsNewChat = (type: true | false) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(ChatActions.setIsNewChat(type));
    };
};

export const setChatTypeForUpdateMessage = (type: "voice" | "text") => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(ChatActions.setChatTypeForUpdateMessage(type));
    };
};

export const restartConversation = (restart: boolean) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(ChatActions.setRestartConversation(restart));
    };
};

export const clearVideoUrls = (messageId: string) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(ChatActions.clearVideoUrls(messageId));
    };
};

export const textToVideo = (message: Record<any, any>) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            dispatch(ChatActions.setSendMessageApiStatus("inprogress"));
            const response = await ChatService.textToVideo(message.message_id);
            // console.log(response.data.text);
            dispatch(ChatActions.appendMessage(message));
            dispatch(ChatActions.setSendMessageApiStatus("success"));

            if (
                response.data.text &&
                Array.isArray(response.data.text) &&
                response.data.text.length > 0
            ) {
                const videoUrls = response.data.text.map((videoBytes) => {
                    const byteCharacters = window.atob(videoBytes);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const videoBlob = new Blob([byteArray], {
                        type: "video/mp4",
                    });
                    const videoUrl = URL.createObjectURL(videoBlob);
                    return videoUrl;
                });

                dispatch(
                    ChatActions.updateMessageIdVideoUrls(
                        message.message_id,
                        videoUrls,
                    ),
                );
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const retryLastFailedMessage = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            const currentState = getState().chatV2Reducer;
            const userMessages = currentState.messages.filter(
                (item: Record<any, any>) => item.sender === "user",
            );

            if (userMessages.length > 0) {
                const lastMessage = userMessages[userMessages.length - 1];
                dispatch(
                    postNewMessage(
                        lastMessage.message,
                        lastMessage.chat_type,
                        lastMessage.chat_input_type,
                        currentState.chatSessionId,
                        false,
                    ),
                );
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const handleFetchMessages = (chatId: string) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            dispatch(ChatActions.fetchMessagesRequest());

            const response = await ChatHistoryService.fetchSessionMessages(
                chatId,
            );

            const messages = response.data;

            dispatch(ChatActions.fetchMessagesSuccess(chatId, messages));
        } catch (error) {
            dispatch(ChatActions.fetchMessagesFailure(error));
            console.error(`Error fetching messages for chat ${chatId}:`, error);
        }
    };
};
